.TopTitle {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: black;
}

.TopDate {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -0.5px;
  color: #3C3C3C;
}

.ChartContainer1 {
  position: relative;
  height: 160px;
}

.ChartContainer {
  display: flex;
  justify-content: space-between;
  /* border: 1px dashed lightgray; */
  position: absolute;
  width: calc(100% - 40px);
  top: 0;
  left: 40px;
}

.HealthButtonLink {
  border-top-right-radius: 8.905px;
  border-bottom-right-radius: 8.905px;
  border: 0.891px solid #BDBDBD;
  border-left: none;
  box-shadow: 0px 2.672px 2.672px 0px rgba(0, 0, 0, 0.10);
}

.HealthButtonSelected {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 5px;
  margin: 5px;
  margin-right: 0;
  border-top-left-radius: 8.905px;
  border-bottom-left-radius: 8.905px;
  border: 0.891px solid #BDBDBD;
  background: transparent;
  box-shadow: 0px 2.672px 2.672px 0px rgba(0, 0, 0, 0.10);
  text-align: center;
  width: 172px;
  cursor: pointer;
}

.HealthButtonSelectedMobile {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 5px;
  margin: 5px;
  margin-right: 0;
  border-top-left-radius: 8.905px;
  border-bottom-left-radius: 8.905px;
  border: 0.891px solid #BDBDBD;
  background: transparent;
  box-shadow: 0px 2.672px 2.672px 0px rgba(0, 0, 0, 0.10);
  text-align: center;
  width: 160px;
  cursor: pointer;
}

.HealthButtonUnSelected {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 5px;
  margin: 5px;
  margin-right: 0;
  border-top-left-radius: 8.905px;
  border-bottom-left-radius: 8.905px;
  border: 0.891px solid #BDBDBD;
  background-color: #f9f9f9;
  box-shadow: 0px 2.672px 2.672px 0px rgba(0, 0, 0, 0.10);
  text-align: center;
  width: 172px;
  cursor: pointer;
}

.HealthButtonUnSelectedMobile {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 5px;
  margin: 5px;
  margin-right: 0;
  border-top-left-radius: 8.905px;
  border-bottom-left-radius: 8.905px;
  border: 0.891px solid #BDBDBD;
  background-color: #f9f9f9;
  box-shadow: 0px 2.672px 2.672px 0px rgba(0, 0, 0, 0.10);
  text-align: center;
  width: 160px;
  cursor: pointer;
}

.HealthButtonIconContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.HealthButtonCount {
  font-weight: bold;
  line-height: initial;
  font-size: 21px;
}

.HealthButtonText {
  font-weight: bold;
  line-height: 1.3em;
}

.HorizHealthSubContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.HorizHealthSubTop {
  display: flex;
  align-items: center;
}

.HealthCircle {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 5px;
}

.TooltipLevelContainer {
  padding: 4px;
  text-align: center;
  width: 90px;
}

.TooltipIconContainer {
  display: flex;
  justify-content: center;
}

.OverLay {
  position: absolute;
  display: flex;
  background-color: #eceaea;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  opacity: 0.4;
  filter: alpha(opacity=40);
  top: 0;
  left: 0;
}

.Icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  border-radius: 4px;

  & > svg {
    width: 14px;
  }
}

.StyledBox>div {
  width: 20px;
  height: 20px;
}
.StyledBox>svg {
  width: 18px;
  height: 18px;
}

.StyledBox2 div {
  width: 20px;
  height: 20px;
}
.StyledBox2 svg {
  width: 12px;
  height: 12px;
}

.BarContainer {
  display: flex;
  height: 160px;
  width: 25px;
  flex-direction: column-reverse;
}

.BarSubContainer {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}

.HorizontalGrid {
  width: 100%;
  height: 1px;
  border-top: 1px dashed lightgrey;
  position: absolute;
}

.VerticalGrid {
  width: 1px;
  height: 100%;
  border-left: 1px dashed lightgrey;
  position: absolute;
}

.VerticalLabels {
  position: absolute;
  /* left: -10px; */
}
