.TopTitle {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: black;
}

.TopDate {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -0.5px;
  color: #3C3C3C;
}

.ChartContainer1 {
  position: relative;
  height: 160px;
}

.ChartContainer {
  display: flex;
  justify-content: space-between;
  /* border: 1px dashed lightgray; */
  position: absolute;
  width: calc(100% - 40px);
  top: 0;
  left: 40px;
}

.HealthButtonSelected {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 5px;
  margin: 5px;
  border-radius: 8.905px;
  border: 0.891px solid #BDBDBD;
  background-color: transparent;
  border-radius: 11px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 2.672px 2.672px 0px rgba(0, 0, 0, 0.10);
  flex-wrap: wrap;
}

.HealthButtonUnSelected {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 5px;
  margin: 5px;
  border-radius: 8.905px;
  border: 0.891px solid #BDBDBD;
  background-color: #f9f9f9;
  border-radius: 11px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 2.672px 2.672px 0px rgba(0, 0, 0, 0.10);
  flex-wrap: wrap;
}

.HealthButtonIconContainer {
  display: flex;
  justify-content: center;
}

.HealthButtonCount {
  text-wrap: nowrap;
  font-weight: bold;
  line-height: initial;
  font-size: 21px;
}

.HealthButtonText {
  line-height: 1.3em;
}

.HorizHealthSubContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.HorizHealthSubTop {
  display: flex;
  align-items: center;
}

.HealthCircle {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 5px;
}

.TooltipLevelContainer {
  padding: 4px;
  text-align: center;
  width: 40px;
}

.TooltipIconContainer {
  display: flex;
  justify-content: center;
}

.OverLay {
  position: absolute;
  display: flex;
  background-color: #eceaea;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  opacity: 0.4;
  filter: alpha(opacity=40);
  top: 0;
  left: 0;
}

.StyledBox>div {
  width: 20px;
  height: 20px;
  margin: 0 4px;
  border-radius: 4px;
}

.StyledBox2 div {
  width: 16px;
  height: 16px;
  margin: 0 4px;
  border-radius: 4px;
}

.BarContainer {
  display: flex;
  height: 160px;
  width: 25px;
  flex-direction: column-reverse;
}

.BarSubContainer {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}

.HorizontalGrid {
  width: 100%;
  height: 1px;
  border-top: 1px dashed lightgrey;
  position: absolute;
}

.VerticalGrid {
  width: 1px;
  height: 100%;
  border-left: 1px dashed lightgrey;
  position: absolute;
}

.VerticalLabels {
  position: absolute;
  /* left: -10px; */
}

.tooltipHolder {
  pointer-events: auto !important;
}
.tooltipHolder:hover {
  visibility: visible !important;
  opacity: 1 !important;
}

.tooltip2Title {
  margin-top: 8px;
}
